import React from "react"
import { Helmet } from "react-helmet"

import Hero from "../components/Hero"
import Button from "../components/Button"

import { AccessibilityNew } from "@material-ui/icons"

// export const metadata = {
// 	title: "Home",
// 	og_description: "Lorem ipsum",
// 	og_image: "about:blank"
// }

const Home = () => (
	<div className="Home">
		<Helmet>
			<title>Fix Meetings</title>
			<meta name="og:title" content="Fix Meetings" />
			<meta name="og:description" content="Lorem ipsum" />
			<meta name="og:image" content="http://localhost/og-image.png" />
		</Helmet>
		<Hero>
			<SVGArt>
				<AccessibilityNew />
			</SVGArt>
			<h1>Meetings are like Wordpress.</h1>
		</Hero>
		<Hero>
			<h2>Survey</h2>
			<p>
				Phasellus maximus lorem vel orci pretium, id tempus arcu lobortis. Proin
				vehicula diam sed est tempus condimentum. Duis lacinia, eros eu eleifend
				ultricies, sapien felis pellentesque enim.
			</p>
			<p>
				<Button href="https://docs.google.com/forms/d/1nOxZeUAgJJ0llJ_osyyUO1-9nnx4nV7zW1cXuEjK0WY">
					Do the survey thing
				</Button>
			</p>
		</Hero>
	</div>
)

export default Home

const SVGArt = ({ children }) => <div className="svg-art">{children}</div>
