import React from "react"
import { Helmet } from "react-helmet"

// export const metadata = {
// 	title: "Contact",
// 	og_description: "Lorem ipsum",
// 	og_image: "about:blank"
// }

const Contact = () => (
	<div className="Contact Page">
		<Helmet>
			<title>Contact | Fix Meetings</title>
			<meta name="og:title" content="Content" />
			<meta name="og:description" content="Lorem ipsum" />
			<meta name="og:image" content="http://localhost/og-image.png" />
		</Helmet>
		<h1>Get in touch with us</h1>
		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada
			fames.
		</p>
	</div>
)

export default Contact
