import Typography from "typography"
import WebFont from "webfontloader"

WebFont.load({
	google: {
		families: ["Noto Sans:300,400,700"]
	}
})

const typography = new Typography({
	baseFontSize: "21px",
	baseLineHeight: 1.7,
	headerFontFamily: ["Noto Sans", "sans-serif"],
	bodyFontFamily: ["Noto Sans", "sans-serif"]
	// See below for the full list of options.
})

typography.injectStyles()
