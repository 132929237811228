import React from "react"
import { Link } from "react-router-dom"
import Headroom from "react-headroom"

import "./Header.sass"

const Header = () => (
	<Headroom>
		<header className="Header">
			<h1>
				<Link to="/">Fix Meetings</Link>
			</h1>
			<nav>
				<p>
					<Link to="/about">Manifesto</Link> |{" "}
					<Link to="/contact">Contact</Link>
				</p>
			</nav>
		</header>
	</Headroom>
)

export default Header
