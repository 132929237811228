import React from "react"
import { Helmet } from "react-helmet"

import "./About.sass"

// export const metadata = {
// 	title: "About",
// 	og_description: "Lorem ipsum",
// 	og_image: "about:blank"
// }

const About = () => (
	<div className="About Page">
		<Helmet>
			<title>About | Fix Meetings</title>
			<meta name="og:title" content="About" />
			<meta name="og:description" content="Lorem ipsum" />
			<meta name="og:image" content="http://localhost/og-image.png" />
		</Helmet>
		<h1>What we had in mind</h1>

		<p>
			<strong>Pellentesque habitant morbi tristique</strong> senectus et netus
			et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat
			vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet
			quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris
			placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
			pharetra. Vestibulum erat wisi, condimentum sed,{" "}
			<code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit
			eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus
			enim ac dui. <a href="http://localhost:3000">Donec non enim</a> in turpis
			pulvinar facilisis. Ut felis.
		</p>

		<h2>Header Level 2</h2>

		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas.
		</p>

		<ol>
			<li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
			<li>Aliquam tincidunt mauris eu risus.</li>
		</ol>

		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas.
		</p>

		<blockquote>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna.
				Cras in mi at felis aliquet congue. Ut a est eget ligula molestie
				gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis,
				tellus est malesuada tellus, at luctus turpis elit sit amet quam.
				Vivamus pretium ornare est.
			</p>
		</blockquote>

		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas.
		</p>

		<ul>
			<li>
				Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod
				in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.
			</li>
			<li>
				Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue,
				eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi,
				tincidunt quis, accumsan porttitor, facilisis luctus, metus.
			</li>
			<li>
				Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer
				ligula vulputate sem tristique cursus. Nam nulla quam, gravida non,
				commodo a, sodales sit amet, nisi.
			</li>
			<li>
				Pellentesque fermentum dolor. Aliquam quam lectus, facilisis auctor,
				ultrices ut, elementum vulputate, nunc.
			</li>
		</ul>

		<h3>Header Level 3</h3>

		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas.
		</p>

		<ul>
			<li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
			<li>Aliquam tincidunt mauris eu risus.</li>
		</ul>

		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas.
		</p>

		<pre>
			<code>
				{`
#header h1 a {
  display: block;
  width: 300px;
  height: 80px;
}
				`}
			</code>
		</pre>
		<p>
			Pellentesque habitant morbi tristique senectus et netus et malesuada fames
			ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget,
			tempor sit amet, ante. Donec eu libero sit amet quam egestas semper.
			Aenean ultricies mi vitae est. Mauris placerat eleifend leo.
		</p>
	</div>
)

export default About
