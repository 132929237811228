import React from "react"
import { BrowserRouter, Route } from "react-router-dom"

import Header from "./components/Header"
import Footer from "./components/Footer"

// import { Home, About } from "./pages"
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"

import "./App.sass"

import "./utils/typography"

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="App">
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default App
